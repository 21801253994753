// Import all of Bootstrap's CSS
//@import "~bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap";

.fill-content {
    height: 100vh;
}

.brand {
    padding-right: 20px;
}

.brand img {
    width: 150px;
}

.slogan {
    font-size: 1.2rem;
    font-weight: bolder;
    font-family: Poppins,Helvetica Neue,Helvetica,Arial,sans-serif,Roboto;
    padding-top: 20px;
}

.gradient-border {
    min-height: 50vh;
    border-left: 5px solid;
    border-image: linear-gradient(45deg, purple, orange) 1;
}

.boxshadow-left {
    box-shadow: -8px -7px 42px -38px #666;
}

.syst-custom {
    margin: 15px 0;
}

.title-medium {
    font-size: 0.85rem;
    font-family: Poppins,Helvetica Neue,Helvetica,Arial,sans-serif,Roboto;
}

.btn-block {
    width: 100%;
}

.box-success {
    color: #fff;
    background-color: rgb(63, 196, 63);
    height: 150px;
    width: 100%;
}

.hidden {
    display: none;
}

.magic-title {
    display: grid;
    place-content: center;
    min-height: 50vh;
    // background: #000;
}

.magic-title h1 {
    font-size: 36px;
    font-weight: 800;
    letter-spacing: -.06em;
    text-align: center;
    margin: 0 0 16px;
}

.magic-title h1 span {
    color: #000;
    display: block;
    line-height: 1.1;
    padding: 0 15px 5px 15px;
    position: relative;
}

.magic-title h1 span::before {
    background: linear-gradient(90deg, var(--start-color), var(--end-color));
    content: var(--content);
    position: absolute;
    inset: 0;
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animated 9s infinite;
    animation-delay: var(--delay);
}

@keyframes animated {
    0%, 16.667%, 100% {
        opacity: 1;
    }

    33.333%, 83.333% {
        opacity: 0;
    }
}














